<template>
  <div class="flex flex-column">
    <div class="info flex flex-column fg-lime">
      <transition name="valchange" mode="out-in">
        <h1 :key="value1" class="inline-block mb-0 mt-0 cursor-pointer" @click="$emit('showChart', $event)">  
            <span :class="valueClass"></span>{{ value1 }}<span class="text-sm inline-block" style="vertical-align: super">{{ unit1 }}</span>
        </h1>
      </transition>
    </div>
  </div>
  <dialog-chart-single :icon="value.icon" :node="value" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import dialogChartSingle from '@/components/dialog/chartSingle.vue';

export default defineComponent({
  name: "widgetGuiValue",
  setup() {},
  components: {
    dialogChartSingle,
  },
  props: {
    value: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      showChart1: false,
    }
  },
  watch: {

  },
  computed: {
    valueClass() {
      if (this.value !== undefined && this.value.unit !== undefined && this.value.value !== undefined) {
        if (this.value.unit === "#BOOL") {
          console.log(this.value)
          if (this.value.value === "true") return "enat-icon-switch fg-green";
          else return "enat-icon-switch fg-red";
        }
        return "bg-white";
      }
      return "";
    },
    value1: function () {
      if (typeof(this.value) === "object") return this.value.value;
      else if (typeof(this.value) === "string") return this.value;
      return '';
    },
    unit1: function () {
      if (typeof(this.value) === "object") {
        if (this.value.unit.includes("#")) return '';
        return this.value.unit;
      }
      return '';
    },
  },
  mounted() {
  },
  methods: {
    showChart(value) {
      if (value === 'value1') this.showChart1 = true;
    },
  },
});
</script>

<style lang="scss" scoped>
</style>